import React from 'react';
const Privacy = () => (

    <div style={{
        maxWidth: '800px',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#f1f6fd',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    }}>
        <h2 style={{textAlign: 'center'}}>XLab 隐私政策&法律声明</h2>
        <p style={{marginBottom: '20px', display: 'block', fontSize: '16px'}}>
            欢迎您使用 XLab 产品。XLab
            产品仅供个人学习研究和交流使用，不做其他商用。请您在继续使用产品前务必认真仔细阅读并确认充分理解本法律声明的全部规则和要点，一旦您选择使用，即视为您同意全部内容，如您在阅读过程中，对本声明有任何疑问，应停止使用本站的产品和服务。如您不同意其中的任何条款的，您也应停止使用本站的产品和服务。</p>
        <h3>一、您对本站的使用</h3>
        <p style={{marginBottom: '20px', display: 'block', fontSize: '16px'}}>
            您在使用本产品的过程中所提供的各种信息（包括但不限于个人信息等），本站不做保留。您同意在使用本站及其内容时，遵守国家法律法规、社会公共道德。您不得利用本站及其内容从事制作、查阅、复制和传播任何违法、侵犯他人权益等扰乱社会秩序、破坏社会稳定的行为，亦不得利用本站及其内容从事任何危害或试图危害计算机系统及网络安全的活动。</p>
        <h3>二、免责声明</h3>
        <p style={{marginBottom: '20px', display: 'block', fontSize: '16px'}}>
            您在使用本产品的过程中提供的各种信息，如出现违法违规或反对党和政府的言论和行为，本站可在不经过您同意的情况下，根据国家有权机关的要求下，提供相关资料。</p>
        <p style={{marginBottom: '20px', display: 'block', fontSize: '16px'}}>
            以下情况无需经过您的同意：</p>
        <ol style={{marginBottom: '20px', display: 'block', fontSize: '16px'}}>
            <li style={{lineHeight: '25px'}}>与我们履行法律法规规定的义务相关的；</li>
            <li style={{lineHeight: '25px'}}>与国家安全、国防安全直接相关的；</li>
            <li style={{lineHeight: '25px'}}>与公共安全、公共卫生、重大公共利益直接相关的；</li>
            <li style={{lineHeight: '25px'}}>与刑事侦查、起诉、审判和执行判决等直接相关的；</li>
            <li style={{lineHeight: '25px'}}>我们有充分证据表明您存在主观恶意或滥用权利的；</li>
            <li style={{lineHeight: '25px'}}>出于维护您或其他个人信息主体的生命、财产等重大合法权益 但又很难得到本人授权同意的；</li>
            <li style={{lineHeight: '25px'}}>涉及商业秘密的；</li>
        </ol>
        <h3>三、本站如何使用sessionStorage、localStorage技术</h3>
        <p style={{marginBottom: '20px', display: 'block', fontSize: '16px'}}>
            为使您获得更轻松的访问体验，您访问本站平台或使用本站提供的服务时，本站会使用sessionStorage、localStorage，记录您的账户及账户的行为状态，比如该账户是否登录、是否完成鉴权等。本站不会将localStorage用于本隐私政策所述目的之外的任何用途。您通常可根据自己的需要来修改浏览器的设置以拒绝localStorage；另外，您也可以清除软件内保存的localStorage。</p>
    </div>
);

export default Privacy;